
.arrowLeft {
    display: flex;
    margin:auto;
    justify-content: center;
    cursor: pointer;
    width: 5%;
    font-size: 36px;
}
.arrowLeft:hover {
    background-color: #570000;
}
.arrowRight {
    display: flex;
    margin:auto;
    justify-content: center;
    cursor: pointer;
    width: 5%;
    font-size: 36px;
}
.arrowRight:hover {
    background-color: #570000;
}


.currentOfferTab {
    background-color: #5f0000;
    color: #ffffff;
    -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start;
    padding: 0;
    margin:12px 0;
    min-height:560px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin:auto;
    justify-content: center;
}

.currentOffersList {
    width:90%;
    color: #ffffff;
    align-items: left;
    padding: 0 ;
    margin:12px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
}
.offerTab {
    width: 100%;
    background-color: #5f0000;
}

.currentOffer {
    background-color: #860000;
    color: #ffffff;
    width:120px;
    height:60px;
    padding: 4px 4px;
    margin: 8px 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.currentOffer:hover {
    background-color: #bb0000;
}

.offerPrice {
    width:100%;
    text-align: right;
}
.offerName {
    width:100%;
    text-align: center;
}


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .offerContainer {
        width:100%;
        background-color: #810000;
        color: #ffffff;
        justify-content: space-between;
        align-items: center;
        padding: 24px 0px 24px 0px;
        margin:0;
        display: block;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .offerContainer {
        width: 80%;
        background-color: #810000;
        color: #ffffff;
        justify-content: space-between;
        align-items: center;
        padding: 24px 10% 24px 10%;
        margin:0;
        display: block;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}