.App {
  background-color: #ffffff;
  width:100%;
  color: #000000;
  text-align: center;
  padding: 0;
  margin:0;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .Container {
    width:120%;
    background-color: #f5f3cd;
    margin:0;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .Container {
    background-color: #f5f3cd;
    min-width: 768px;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin:auto;
  }
}