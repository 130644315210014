body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2, h3 {
  font-family: 'Verdana';
}

span, p {
  font-family: 'Georgia';
}

button:hover {
  background-color:  #a10000;
}

@font-face {
  font-family: 'SaucyMillionaire';
  src: url('./assets/font/SaucyMillionaire.eot');
  src: url('./assets/font/SaucyMillionaire.eot?#iefix') format('embedded-opentype'),
      url('./assets/font/SaucyMillionaire.woff2') format('woff2'),
      url('./assets/font/SaucyMillionaire.woff') format('woff'),
      url('./assets/font/SaucyMillionaire.ttf') format('truetype'),
      url('./assets/font/SaucyMillionaire.svg#SaucyMillionaire') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  h1 {  
    margin: 4px 0px;
    font-size: 32px;
    font-family: 'SaucyMillionaire';
    cursor: pointer;
  }

  button {
    background-color:  #940000;
    padding:8px 16px;
    margin:4px;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */

  h1 {  
    margin: 8px 0px;
    font-size: 64px;
    font-family: 'SaucyMillionaire';
    cursor: pointer;
  }

  button {
    background-color:  #940000;
    padding:10px 20px;
    margin:4px;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
  }
}
