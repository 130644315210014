.logoContainer {
    width: 100%;
    height: auto;
    background-color: #810000;
    color: #ffffff;
    align-items: center;
    margin:32px 0 0 0;
    padding:0;
    display: block;
}

.logo {
    width: 100%;
    height: auto;
}


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .logoContainer {
        width: 100%;
        height: auto;
        background-color: #810000;
        color: #ffffff;
        align-items: center;
        margin:48px 0 0 0;
        padding:0;
        display: block;
    }
  }
  
  @media only screen and (min-width: 768px) {
    /* For desktop: */
    .logoContainer {
        width: 100%;
        height: auto;
        background-color: #810000;
        color: #ffffff;
        align-items: center;
        margin:32px 0 0 0;
        padding:0;
        display: block;
    }
  }