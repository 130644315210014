
.headerAddress {
    position:absolute;
    left:24px;
    bottom: 6px;
    font-size: 12px;
}
.headerLink {
    margin-left: auto;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    
.headerAddress {
    position:absolute;
    left:24px;
    top: 34px;
    font-size: 12px;
}

    .headerContainer {
        position:fixed;
        top: 0%;
        width: 100%;
        background-color: #700101;
        color: #ffffff;
        align-items:center;
        padding: 0px 0px;
        margin:0px;
        border-bottom-left-radius: 36px;
        border-bottom-right-radius: 36px;
        z-index: 100000;
        justify-content: right;
    }
  }
  
  @media only screen and (min-width: 768px) {
    /* For desktop: */

    .headerAddress {
        position:absolute;
        left:24px;
        bottom: 6px;
        font-size: 12px;
    }

    .headerContainer {
        position:fixed;
        top: 0%;
        width: 1152px;
        background-color: #700101;
        color: #ffffff;
        align-items:center;
        padding: 0 24px;
        margin:0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-bottom-left-radius: 36px;
        border-bottom-right-radius: 36px;
        z-index: 100000;
        justify-content: right;
    }
  }