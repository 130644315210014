.photoContainer {
    width: 100%;
    background-color: #5f0000;
    color: #ffffff;
    align-items: center;
    padding: 24px 0 24px 0;
    margin:12px 0;
    display: block;
    border-radius: 12px;
}

.buttonContainer {
    width: 100%;
    color: #ffffff;
    align-items: center;
    padding: 6px 0 6px 0;
    margin:12px 0;
    display: block;
    border-radius: 12px;
}

.currentPhoto {
    height: 600px;
    background-color: #5f0000;
    color: #ffffff;
    align-items: center;
    padding: 0 ;
    margin:12px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin:auto;
    justify-content: center;
}

.currentPhoto img {

    max-width: 90%;
}

.arrowLeft {
    display: flex;
    margin:auto;
    justify-content: center;
    cursor: pointer;
    width: 5%;
    font-size: 36px;
}
.arrowLeft:hover {
    background-color: #570000;
}
.arrowRight {
    display: flex;
    margin:auto;
    justify-content: center;
    cursor: pointer;
    width: 5%;
    font-size: 36px;
}
.arrowRight:hover {
    background-color: #570000;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .galleryContainer {
        background-color: #860000;
        color: #ffffff;
        align-items: center;
        padding: 0px 0px 24px 0px;
        margin:0;
        display: block;
        border-radius: 12px;
    }    

    .currentPhoto {
        height: 300px;
        background-color: #5f0000;
        color: #ffffff;
        align-items: center;
        padding: 0 ;
        margin:12px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:auto;
        justify-content: center;
    }
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .galleryContainer {
        width: 80%;
        background-color: #860000;
        color: #ffffff;
        align-items: center;
        padding: 0px 10% 24px 10%;
        margin:0;
        display: block;
        border-radius: 12px;
    }

    
    .currentPhoto {
        width: 100%;
        background-color: #5f0000;
        color: #ffffff;
        align-items: center;
        padding: 0 ;
        margin:12px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:auto;
        justify-content: center;
    }
}