img{
    image-orientation: flip;
}
.gd-img{
    image-orientation: from-image;
}
  
.gd-pointer{
    image-orientation: from-image;
    cursor: pointer;
}
  
/* Style the Image Used to Trigger the Modal */
.gd-hover {
    transition: 0.3s;
}
  
.gd-hover:hover {opacity: 0.7;}
