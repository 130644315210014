

.links {
    width: 33%;
    height: 100%;
}

.opening {
    width: 34%;
    height: 100%;
}

.contacts {
    width: 33%;
    height: 100%;
}

.contactText {
    text-align:center;
}




@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .contactContainer {
        width: 100%;
        background-color: #f5f3cd;
        color: #000000;
        align-items: top;
        padding: 0px 0px 24px 0px;
        margin:0;
        justify-content: top;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .contactContainer {
        width: 80%;
        background-color: #f5f3cd;
        color: #000000;
        align-items: top;
        padding: 0px 10% 24px 10%;
        margin:0;
        justify-content: top;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}