

.article {
    text-align:left;
    text-align: justify;
}

.article span {
    padding-left: 3em; 
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .aboutContainer {
        width: 100%;
        background-color: #f3f2d7;
        color: #000000;
        align-items: center;
        padding: 0px 0px 24px 0px;
        margin:0;
        display: block;
    }
  }
  
  @media only screen and (min-width: 768px) {
    /* For desktop: */
    .aboutContainer {
        width: 80%;
        background-color: #f3f2d7;
        color: #000000;
        align-items: center;
        padding: 0px 10% 24px 10%;
        margin:0;
        display: block;
    }
  }