  .modal {
    position: fixed; 
    z-index: 1; 
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.6);
  }

  .modalContainer {
    margin: auto;
    display: block;
    z-index: 3;
    width: 80%;
    max-width: 700px;
  }
  

  .modalTitle {
    margin: auto;
    display: block;
    z-index: 4;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }
  
  .modalContainer, .modalTitle {
    animation-name: zoom;
    animation-duration: 0.6s;
  }
  
  @keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
  }
  
  .close {
    position: absolute;
    z-index: 5;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  @media only screen and (max-width: 700px){
    .modalContainer {
      width: 100%;
    }
  } 