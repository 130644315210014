@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .footerContainer {        
        width: 100%;
        background-color: #8b0000;
        color: #ffffff;
        align-items: center;
        padding: 12px 0;
        margin:0;
        display: block;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .footerContainer {
        width: 100%;
        background-color: #8b0000;;
        color: #ffffff;
        align-items: center;
        padding: 12px 0;
        margin:0;
        display: block;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
}